* {
	box-sizing: border-box;
}
.header {
	background: #F55A5A;
	color: white;
	font-size: 2.5rem;
	display: flex;
	justify-content: center;
	padding: 1rem;
}
.card-container {
	margin: 3rem;
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	gap: 1rem;
}
.card {
	display: flex;
	flex-flow: row;
	max-width: fit-content;
	height: 250px;
	gap: 19px;
	border-bottom: 1px solid #F5F5F5;
	padding: 2rem 1rem;
	margin: auto;
}
.card__content {
	display: flex;
	flex-flow: column;
	gap: 1rem;
}
.card__img {
	border-radius: 5px;
	object-fit: cover;
}
@media screen and (min-width: 1190px) {
	.card__img {
		width: 20vw;
	}
}
.card__location {
	letter-spacing: 0.17em;
	font-size: 1rem;
	text-transform: uppercase;
}
.card__location .fa-location-dot {
	color: #F55A5A;
}
.card__title {
	font-size: 2rem;
	font-weight: bold;
}
.card__date {
	margin-top: 17px;
	font-size: .8rem;
	font-weight: bold;
}
.card__date__endDate::before {
	content: " - ";
}
.card__description {
	width: 250px;
	font-size: .9rem;
}